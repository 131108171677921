<template>
  <div class="basicinfo">
    <div class="invoicet"><Head title="基本信息"></Head></div>
    <div v-if="!isEdit" class="cont el">
      <el-row >
        <el-col :span="8"><div class="l-title">头像：</div></el-col>
        <el-col :span="16">
          <div style="text-align: left;">
            <img class="avatar" :src="info.avatar" style="width: 80px; height:80px;vertical-align: middle;" />

          </div>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">用户名：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.userName }}</div></el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">民族：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.nickName }}</div></el-col>
      </el-row> -->
      <el-row>
        <el-col :span="8"><div class="l-title">姓名：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.name }}</div></el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">昵称：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.nickName }}</div></el-col>
      </el-row> -->
      <el-row>
        <el-col :span="8"><div class="l-title">身份证：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.idCard }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">性别：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.gender }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">是否为少数民族：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.minorityNationality === 1 ? '是' : '否' }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">联系电话：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.contactNumber }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">邮箱：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.email }}</div></el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">自我介绍：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.status }}</div></el-col>
      </el-row> -->
      <el-row>
        <el-col :span="8"><div class="l-title">联系地址：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.address}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">工作单位：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.company }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">职业：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.vocation }}</div></el-col>
      </el-row>

      <el-row>
        <el-col :span="8"><div class="l-title">职称：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.vocationTitle}}</div></el-col>
      </el-row>
      <!-- 暂时不需要 <el-row>
        <el-col :span="8"><div class="l-title">证书编号：</div></el-col>
        <el-col :span="16"><div class="r-cont">舒服舒服</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">三类人员证书编号：</div></el-col>
        <el-col :span="16"><div class="r-cont">舒服舒服</div></el-col>
      </el-row> -->

      <!-- <el-row>
        <el-col :span="8"><div class="l-title">身份证号：</div></el-col>
        <el-col :span="16"><div class="r-cont">44535323231313213131</div></el-col>
      </el-row> -->
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">通信地址：</div></el-col>
        <el-col :span="16"><div class="r-cont">天山区西河坝前街111号</div></el-col>
      </el-row> -->
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">邮箱：</div></el-col>
        <el-col :span="16"><div class="r-cont">hbing@163.com</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">我的简历：</div></el-col>
        <el-col :span="16"><div class="r-cont"><a href="https://i.postimg.cc/gcQ005jx/image.png" target="_blank">https://i.postimg.cc/gcQ005jx/image.png</a></div></el-col>
      </el-row> -->
      <div class="btn-wrap">
        <!-- <el-button style="width: 120px" @click="cancel">取消</el-button> -->
        <el-button
            type="primary"
            style="width: 120px; margin-right: 50px"
            @click="btnEdit"
        >编辑</el-button
        >
      </div>
    </div>
    <!-- 编辑 -->
    <div v-else class="cont el">
      <el-form
          ref="ruleFormRules"
          v-loading="fromloding"
          :model="ruleForm"
          :rules="ruleFormRules"
          label-width="150px"
          class="demo-ruleForm"
          :validate-on-rule-change="true"
      >
        <el-form-item label="头像：" prop="imgUrl">
          <!-- <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="info.img_url" class="avatar" />
            <i v-else class="el-icon-camera-solid avatar-uploader-icon"></i>
          </el-upload> -->
          <div>
            <Image-upload
                :file-arr="ruleForm.img_url"
                @getUploadList="getUploadList"
            />
            <p class="image-upload-tip">
              支持JPG\JPEG\PNG格式，不超过5M！
            </p>
          </div>
        </el-form-item>
        <el-form-item label="昵称：" prop="nickName">
          <el-input
              v-model="ruleForm.nickName"
              clearable
              type="text"
              placeholder="请输入昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名：" prop="name">
          <el-input
              v-model="ruleForm.name"
              clearable
              type="text"
              placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证：" prop="idCard">
          <el-input
              v-model="ruleForm.idCard"
              clearable
              type="text"
              placeholder="请输入身份证号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="contactNumber">
          <el-input
              v-model="ruleForm.contactNumber"
              clearable
              type="text"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系地址：" prop="address">
          <el-input
              v-model="ruleForm.address"
              clearable
              type="text"
              placeholder="请输入所在地"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="gender">
          <div style="float:left">
            <el-radio-group v-model="ruleForm.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="是否为少数民族：" prop="minorityNationality">
          <div style="float:left">
            <el-radio-group v-model="ruleForm.minorityNationality">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input
              v-model="ruleForm.email"
              clearable
              type="text"
              placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作单位：" prop="company">
          <el-input
              v-model="ruleForm.company"
              clearable
              type="text"
              placeholder="请输入工作单位"
          ></el-input>
        </el-form-item>
        <el-form-item label="职业：" prop="vocation">
          <el-input
              v-model="ruleForm.vocation"
              clearable
              type="text"
              placeholder="请输入职业"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="自我介绍：" prop="IntroduceOneself">
          <el-input
            v-model="ruleForm.IntroduceOneself"
            clearable
            type="text"
            placeholder="请输入您要修改的自我介绍"
          ></el-input>
        </el-form-item> -->

        <!-- <el-form-item label="职业：" prop="Occupation">
          <el-input
            v-model="ruleForm.Occupation"
            clearable
            type="text"
            placeholder="请输入您要修改的职业"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="职称：" prop="vocationTitle">
          <el-input
              v-model="ruleForm.vocationTitle"
              clearable
              type="text"
              placeholder="请输入职称，多个使用逗号隔开"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="身份证号：" prop="IdNumber">
          <el-input
            v-model="ruleForm.IdNumber"
            clearable
            type="text"
            placeholder="请输入您要修改的身份证号"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="通信地址：" prop="MailingAddress">
          <el-input
            v-model="ruleForm.MailingAddress"
            clearable
            type="text"
            placeholder="请输入您要修改的通信地址"
          ></el-input>
        </el-form-item> -->

        <div class="btn-wrap">
          <!-- <el-button style="width: 120px" @click="cancel">取消</el-button> -->
          <el-button
              type="primary"
              style="width: 120px; margin-right: 50px"
              @click="btnSave"
          >保存</el-button
          >
        </div>
      </el-form>
    </div>
    <!-- <el-upload
      class="avatar-uploader"
      action="https://jsonplaceholder.typicode.com/posts/"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-camera-solid avatar-uploader-icon"></i>
    </el-upload>
    <p class="image-upload-tip">
      请上传jpg, gif, png格式的图片, 建议图片大小不超过2MB
    </p>
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      style="margin-top: 50px; width: 100%"
    >
      <span>用户名</span>
      <el-input
        type="text"
        placeholder="请输入内用户名"
        v-model="text"
        maxlength="10"
        show-word-limit
        size="mini"
        style="width: 180px; float: left; margin-top: 10px"
      >
      </el-input>
    </el-form> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getUserDetail,postUserDetail } from '@/api/common'
import ImageUpload from '@/components/Upload/ImageUpload'
import {validEmail, isId15, isId18 } from '../../../utils/validate'

import Head from './Head'
export default {
  name: "Basicinfo",
  components:{
    ImageUpload,
    Head,
  },
  data() {
    return {
      loading: false,
      fromloding: false,
      isEdit: false, // 标记是否编辑状态
      info:{}, //存储接口返回的信息
      ruleForm: {
        // userName:"",//用户名
        // Nation:"",//民族
        // RealName:"",//真实名字
        // Sex:"",//性别
        // Title:"",//头衔
        // IntroduceOneself:"",//自我介绍
        // Company:"",//公司
        // Occupation:"",//职业
        // Location:"",//所在地
        // // ProfessionalTitle:"",//职称专业
        // IdNumber:"",//身份证号
        // MailingAddress:"",//通信地址
        // Email:"",//邮箱
        //
        //
        img_url:["https://www.wlmqcol.com/files/default/2022/12-04/19093601b566612536.png"],// 头像
        createAt:"",//创建时间
        nickName:"",//昵称
        userName:"",//用户名
        name: '', // 姓名
        modifiedAt:"",//
        gender: null,//性别
        address:"",//地址
        contactNumber:"",//联系电话
        email: '',
        company: '',
        vocation: '',
        vocationTitle: '',
        minorityNationality: '',
        idCard: '',



      },
      ruleFormRules: {
        // user_img:[
        //   { required: true, message: '您还没选择头像，请选择一张图片作为您的头像', trigger: 'change' }
        // ],
        // userName:[
        //   { required: true, message: '企业名称不能为空', trigger: 'change' }
        // ],
        // Nation:[
        //   { required: true, message: '名族不能为空', trigger: 'change' }
        // ],
        // RealName:[
        //   { required: true, message: '真实姓名不能为空', trigger: 'change' }
        // ],
        // Sex:[
        //   { required: true, message: '性别不能为空', trigger: 'change' }
        // ],
        // Title:[
        //   { required: true, message: '头衔不能为空', trigger: 'change' }
        // ],
        // IntroduceOneself:[
        //   { required: true, message: '自我介绍不能为空', trigger: 'change' }
        // ],
        // Company:[
        //   { required: true, message: '公司不能为空', trigger: 'change' }
        // ],
        // Occupation:[
        //   { required: true, message: '职业不能为空', trigger: 'change' }
        // ],
        // Location:[
        //   { required: true, message: '所在地不能为空', trigger: 'change' }
        // ],
        // ProfessionalTitle:[
        //   { required: true, message: '职称专业不能为空', trigger: 'change' }
        // ],
        // IdNumber:[
        //   { required: true, message: '身份证号不能为空', trigger: 'change' }
        // ],
        // MailingAddress:[
        //   { required: true, message: '通信地址不能为空', trigger: 'change' }
        // ],
        // Email:[
        //   { required: true, message: '电子邮箱不能为空', trigger: 'change' }
        // ],
      },
      // form表单
      form: {},
      // 用户头像
      // imageUrl: "",
      // inpur输入框
      text: "",
      textarea: "",
    };
  },
  computed: {
    ...mapGetters([
      'nickName',
      'phone',
      'userName',
      'token',
      'role'
    ]),
  },
  created() {
    // console.log(this.$route);
    // this.isEdit = this.$route.query.isEdit == 1 ? true : false // 从注册跳过来完善基本信息
    if(this.$route.query.isEdit == 1) {
      // this.ruleForm.contactNumber = this.$route.query.phone
      this.btnEdit()
    }
    // console.log(this.isEdit);
  },
  mounted() {
    this.getdata()
    if(!this.token) { // 没登录跳首页
      this.$router.push({
        path: '/'
      })
    }
  },
  methods: {
    // handleAvatarSuccess(res, file) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    // beforeAvatarUpload(file) {
    //   // 上传图片格式
    //   const isJPG = file.type === "image/jpeg";
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG) {
    //     this.$message.error("上传头像图片只能是 JPG, Gif, Png格式!");
    //   }
    //   if (!isLt2M) {
    //     this.$message.error("上传头像图片大小不能超过 2MB!");
    //   }
    //   return isJPG && isLt2M;
    // },
    // 编辑
    btnEdit() {
      console.log("edit....");
      this.isEdit= true
      getUserDetail().then((res)=>{
        console.log(res)
        if(res.status === 200){
          if(res.data.code === 0){
            this.ruleForm.img_url = [res.data.data.avatar]
            this.ruleForm.createAt = res.data.data.createAt
            this.ruleForm.nickName = res.data.data.nickName
            this.ruleForm.userName = res.data.data.userName
            this.ruleForm.name = res.data.data.name
            this.ruleForm.modifiedAt = res.data.data.modifiedAt
            this.ruleForm.gender = res.data.data.gender
            this.ruleForm.address = res.data.data.address
            this.ruleForm.contactNumber = res.data.data.contactNumber
            this.ruleForm.email = res.data.data.email
            this.ruleForm.minorityNationality = res.data.data.minorityNationality
            this.ruleForm.idCard = res.data.data.idCard
            this.ruleForm.company = res.data.data.company
            this.ruleForm.vocation = res.data.data.vocation
            this.ruleForm.vocationTitle = res.data.data.vocationTitle

            console.log(this.ruleForm);
          }
        }
      })
    },
    // 保存
    btnSave() {
      let isTag1 = true // 标记
      let isTag2 = true
      if(this.ruleForm.email) {
        // console.log(this.ruleForm.email);
        // console.log(validEmail(this.ruleForm.email));
        if(!validEmail(this.ruleForm.email)) {
          this.$message({
            type: 'error',
            message: '请输入正确邮箱！'
          })
          isTag1 = false
          // this.handleSubmit()

        }else{
          isTag1 = true
        }
      }
      if(this.ruleForm.idCard){
        if(isId15(this.ruleForm.idCard) || isId18(this.ruleForm.idCard)) {
          this.$message({
            type: 'error',
            message: '请输入正确身份证号码！'
          })
          isTag2 = false
        }else{
          isTag2 = true
        }
      }
      if(isTag1 && isTag2) {
        this.handleSubmit()
      }


    },
    //提交数据
    handleSubmit() {
      this.$refs.ruleFormRules.validate((vaild) => {
        if (vaild) {
          console.log('pass...');
          const {img_url,createAt,nickName,name,userName,contactNumber,modifiedAt,gender,address,email,company,vocation,vocationTitle,minorityNationality,idCard} =this.ruleForm
          const obj ={
            avatar: img_url[0],
            createAt,
            nickName,
            name,
            userName,
            contactNumber,
            modifiedAt,
            gender,
            address,
            email,
            company,
            vocation,
            vocationTitle,
            minorityNationality,
            idCard
          }
          postUserDetail(obj).then(res=>{
            if(res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '修改成功！',
                onClose: ()=>{
                  this.isEdit = false
                  this.getdata()
                }
              })
            }else{
              this.$message({
                type: 'error',
                message: res.data.message,
                onClose: ()=>{
                }
              })
            }
          })
        }
      })
    },
    getdata() {
      getUserDetail().then((res)=>{
        console.log(res)
        if(res.status === 200){
          if(res.data.code ===0){
            this.info = res.data.data
            this.info.gender = res.data.data.gender === 3 ? '未知' :  (res.data.data.gender=== 1 ? '男' : '女')
          }
        }else{
          this.$message({
            type:'error',
            message:res.data.message
          })
        }
      })

    },
    getUploadList(val) {
      console.log(val, 'getUploadList');
      this.ruleForm.img_url = val
      // this.payImage = this.ruleForm.img_url

    },
  },
};
</script>

<style lang="scss" scoped>
.basicinfo {
  font-size: 14px;

  background: #fff;
  em {
    display: flex;
    font-size: 18px;
  }
  .cont {
    width: 80%;
    margin: 30px auto 0;
    .l-title {
      margin: 20px 0;
      text-align: right;
      color: #666;
    }
    .r-cont {
      color: #333;
      margin: 20px 0;
      text-align: left;
      .avatar{
        width: 120px;
        height: 120px;
        border-radius: 80px;
        margin-top: -60px;
      }
    }
    .btn-wrap {
      margin-top: 20px;
    }
    .avatar-uploader {
      width: 128px;
      height: 128px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      // margin-top: 20px;
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 128px;
        height: 128px;
        line-height: 128px;
        text-align: center;
        opacity: 0.8;
      }
      .avatar-uploader-icon:hover {
        font-size: 28px;
        color: #8c939d;
        width: 128px;
        height: 128px;
        line-height: 128px;
        text-align: center;
        opacity: 0.5;
      }
      .avatar {
        width: 128px;
        height: 128px;
        display: block;
      }
    }
    .avatar-uploader:hover {
      border-color: #409eff;
      background: rgba(0, 0, 0, 0.7);
      z-index: 100;
      opacity: 0.5;
    }
    .image-upload-tip {
      // position: absolute;
      text-align: left;
      margin-top: 10px;
      font-size: 12px;
      line-height: 1;
      color: rgba(0, 0, 0, 0.32);
    }
  }
  span {
    // font-size: 12px;
    display: flex;
  }
}
.el{
  padding: 30px 20px;
  margin-top: 0px !important;
  min-height: 62vh;
}
.invoicet{
  font-size: 18px !important;
}
</style>
